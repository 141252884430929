import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUDIENCE_URL,
        });
        setAccessToken(token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently]);

  return { accessToken, loading };
};

export const strippedString = (text) => text.replace(/(<([^>]+)>)/gi, "");

export const removeDuplicacyById = (data) => {
  const result = data?.filter(
    (value, index, self) => index === self.findIndex((t) => t?._id === value?._id)
  );
  return result;
};

export const delayPromise = (ms = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const trimTextByCharacterLimit = (text, limit) => {
  // Split the text into sentences based on periods.
  const sentences = text.split(".");
  let trimmedText = "";
  let currentLength = 0;

  // Iterate over each sentence
  for (let sentence of sentences) {
    sentence = sentence.trim();
    if (sentence.length === 0) continue; // Skip any empty results from split

    // Check if adding this sentence plus a period exceeds the limit
    if (currentLength + sentence.length + 1 <= limit) {
      trimmedText += sentence + ".";
      currentLength += sentence.length + 1;
    } else {
      // If adding this sentence would exceed the limit, stop the loop
      break;
    }
  }

  return trimmedText;
};

export const getFormattedLikes = (likes) => {
  const actualLikes = likes ? Number(likes) : 0;
  if (actualLikes < 10000) {
    return actualLikes?.toLocaleString();
  } else if (actualLikes >= 10000 && actualLikes < 1000000) {
    return parseInt(actualLikes / 10000)?.toLocaleString() + "K";
  } else if (actualLikes >= 1000000 && actualLikes < 1000000000) {
    return parseInt(actualLikes / 1000000)?.toLocaleString() + "M";
  } else if (actualLikes >= 1000000000) {
    return parseInt(actualLikes / 1000000000)?.toLocaleString() + "B";
  }
  return actualLikes;
};

export const throttle = (fn, delay) => {
  let run = false;
  return function (...args) {
    if (!run) {
      fn(...args);
      run = true;
      setTimeout(() => (run = false), delay);
    }
  };
};
