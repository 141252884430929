import Axios from "../../utils/axios";

export const readTestimonial = (payload) => {
  const URL = "/testimony/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonyOfTheDay = (payload) => {
    const URL = "/testimony/testimonyOfDay";
    return Axios.post(URL, payload).then((res) => res.data);
  };

export const saveTestimonial = (payload) => {
  const URL = "/testimony/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveTestimonialCategory = (payload) => {
  const URL = "/testimony/category/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonialCategory = (payload) => {
  const URL = "/testimony/category/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonialTrending = (payload) => {
  const URL = "/testimony/trending";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonialLiked = (payload) => {
  const URL = "/testimony/liked";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonialByCategory = (payload) => {
  const URL = "/testimony/bycategory";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimonialInReview = (payload) => {
  const URL = "/testimony/inreview";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTestimoniaApproved = (payload) => {
  const URL = "/testimony/approved";
  return Axios.post(URL, payload).then((res) => res.data);
};
