import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./HomeAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* getProfileRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getProfile, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguage, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readUserFavRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readUserFav, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* userFavRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.userFav, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* userUnFavRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.userUnFav, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getLanguageListRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getLanguageList, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getPickWhereLeftRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getPickWhereLeft, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* savePickWhereLeftRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.savePickWhereLeft, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getAchievementsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getAchievements, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveUserProfileRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    console.log(payload, "payload");
    const response = yield call(api.saveUserProfile, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateUserProfileRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateUserProfile, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* layoutHomeScreenRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.layoutHomeScreen, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* layoutMaincarouselRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.layoutMaincarousel, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* layoutTop10RequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.layoutTop10, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readVideoRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideo, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* uploadAssestsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.uploadAssests, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getPromiseOfTheDayRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getPromiseOfTheDay, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readPromiseRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readPromise, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveSupportRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveSupportRequest, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* savePrayerRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.savePrayerRequest, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* HomeWatcherSaga() {
  yield takeLatest(actionTypes.GET_PROFILE_REQUEST, getProfileRequestSaga);
  yield takeEvery(actionTypes.READ_LANGUAGE_REQUEST, readLanguageRequestSaga);
  yield takeLatest(actionTypes.READ_FAV_REQUEST, readUserFavRequestSaga);
  yield takeLatest(actionTypes.USER_FAV, userFavRequestSaga);
  yield takeLatest(actionTypes.USER_UN_FAV, userUnFavRequestSaga);

  yield takeLatest(
    actionTypes.GET_LANGUAGE_LIST_REQUEST,
    getLanguageListRequestSaga
  );

  yield takeLatest(
    actionTypes.GET_PICK_WHERE_LEFT_REQUEST,
    getPickWhereLeftRequestSaga
  );

  yield takeEvery(
    actionTypes.SAVE_PICK_WHERE_LEFT_REQUEST,
    savePickWhereLeftRequestSaga
  );
  yield takeLatest(
    actionTypes.GET_ACHIEVEMENTS,
    getAchievementsRequestSaga
  );

  yield takeLatest(
    actionTypes.SAVE_USER_PROFILE_REQUEST,
    saveUserProfileRequestSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_USER_PROFILE_REQUEST,
    updateUserProfileRequestSaga
  );

  yield takeLatest(
    actionTypes.LAYOUT_HOME_SCREEN_REQUEST,
    layoutHomeScreenRequestSaga
  );

  yield takeLatest(
    actionTypes.LAYOUT_MAIN_CAROUSEL_REQUEST,
    layoutMaincarouselRequestSaga
  );

  yield takeLatest(actionTypes.LAYOUT_TOP10_REQUEST, layoutTop10RequestSaga);

  yield takeLatest(actionTypes.READ_VIDEO_REQUEST, readVideoRequestSaga);

  yield takeEvery(actionTypes.UPLOAD_ASSEST_REQUEST, uploadAssestsRequestSaga);

  yield takeLatest(
    actionTypes.GET_PROMISE_OF_THE_DAY_REQUEST,
    getPromiseOfTheDayRequestSaga
  );

  yield takeLatest(actionTypes.GET_PROMISE_REQUEST, readPromiseRequestSaga);

  yield takeEvery(actionTypes.SAVE_SUPPORT_REQUEST, saveSupportRequestSaga);

  yield takeEvery(actionTypes.SAVE_PRAYER_REQUEST, savePrayerRequestSaga);
}
