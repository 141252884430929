import jsPDF from "jspdf";
import { useParams, useSearchParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";

import certificateTemplate from "../../assets/images/certificate_template.jpg";
import { useEffect } from "react";
import axios from "axios";

const CertificateDownload = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getCertificateTemplate = (title, name) => {
    return `<div className="w-[792px] relative" style="width:792px;position:relative">
        <img src="" alt="certificate template" className="w-full" style="width:100%"/>
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style="word-spacing:4px;text-transform:uppercase;color:white;font-weight:500;position:absolute;top:205px;left:300px;width:392px"
        >
          <p className="w-full text-center" style="width:100%;text-align:center">${title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style="word-spacing:5px;text-transform:uppercase;font-size:1.875rem;line-height:2.25rem;font-weight:700;position:absolute;top:310px;left:220px;width:552px"
        >
          <p className="w-full text-center" style="width:100%;text-align:center">${name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]" style="font-weight:500;position:absolute;top:380px;right:50px;width:60%;line-height:1;font-size:0.95rem;color:#14303C;text-align-center">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>`;
  };

  const generatePDFDocument = (title = "") => {
    const username = searchParams.get("username");
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(getCertificateTemplate(title, username), {
      callback: async function (doc) {
        doc.save("Certificate" + new Date().getTime());
      },
      margin: [0, 0, 0, 0],
      width: 792,
      windowWidth: 792,
    });
  };

  const getCurriculumDetails = () => {
    const curriculumId = id.split(".")?.[0];
    dispatch(
      curriculumReadAction({
        apiPayloadRequest: { _id: curriculumId },
        callback: (res) => {
          if (res?.type === 1) {
            const curriculum = res?.data[0];
            generatePDFDocument(curriculum?.curriculum_name);
          }
        },
      })
    );
  };

  useEffect(() => {
    // getCurriculumDetails();
  }, []);

  const downloadPDF = () => {
    const formData = new FormData();
    formData.append("_id", "663b932ee22105f04752b079");
    axios("https://todaywithgod.org:4004/curriculum/certificate", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlM4N3RFNFZTSE5BRjBJdERoektadiJ9.eyJpc3MiOiJodHRwczovL2F1dGgudG9kYXl3aXRoZ29kLm9yZy8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDExNjc1OTE3ODUyODY3MjcwNTUzNCIsImF1ZCI6WyJodHRwczovL3RvZGF5d2l0aGdvZC5vcmc6NDAwNC8iLCJodHRwczovL2Rldi02b2YzYzV2M2w1bjh3aGFoLnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE3MjQxNzI0MzIsImV4cCI6MTcyNDI1ODgzMiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF6cCI6IlNiOTN1c0E0RUtTM1R6MVZxNmdwR3RncWdaaDR6a2tXIn0.EhLL5M1Pylf53VHiNwAIvYUikQ_BbCy2piMzwg-e924m5YpW78PYSshnY2GqLbrZqSv8t0lnE7-QHQiSCXbdb7aA8IVwseRCMm4Htl2jbZGLX2vn45EiBU1m5BBSwpVSf0PH40niBul7SeoP4S3F5rW1Vm4WONRkTWuIepWTaiLKW94RWa_4CJsgdKZyUf_itwaeH4Zd3K2A8yT53HsINueZjr_R9Fkk7o7xRhy3LGpeC5KnYw4OctZ8wDmuXTYdZTXZy0IIMGcUjp5GijtTLDFBtDutgeKjrE7kxpm8kkzuOGb9Y-gwBVwNRQPsIcKYIXIxU0Oom6t6yCnGCvyQgg",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="flex flex-col bg-black overflow-y-auto w-full gap-10 min-h-[100vh] relative overflow-auto">
      <button className="text-white" onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
  );
};

export default CertificateDownload;
